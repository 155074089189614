import './App.css';
import { Link } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="grid-container">
          <Link to="/strategicplanner" class="grid-item">Strategic Planner</Link>
          <Link to="/" class="grid-item">Start</Link>
        </div>
      </header>
    </div>
  );
}

export default App;
